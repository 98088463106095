.foot-pic{
    width: 20rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
    padding: 0.5rem;
    border-style: solid;
    border-radius: 1rem;
}

img {
    width: 19rem;
    height: 19rem;
    border: solid;
    border-radius: 1rem;
    border-width: 2px;
}

strong, button {
    font-size: 1rem;
}

h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.price {
    height: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}